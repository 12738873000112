import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import NavMain from "./NavMain";
import Social from "./Social";

//// Styling Component ----------------------------------------------------------------------
const CustomInnerNavBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: "70%",
  px: 5,
  py: 2,
  mx: "auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
  },
}));

const CustomOuterNavBox = styled(Box)(({ theme, scrolled }) => ({
  zIndex: 999,
  position: "fixed",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  background: scrolled,
}));
//// --------------------------------------------------------------------------------------------

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header>
      <CustomOuterNavBox scrolled={scrolled ? "#121212" : "transparent"}>
        <CustomInnerNavBox>
          <NavMain />
        </CustomInnerNavBox>
      </CustomOuterNavBox>
      <Social />
    </header>
  );
};

export default Navbar;
