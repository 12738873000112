import { keyframes } from "@emotion/react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-scroll";
import introBg from "../../assets/intro-bg.webp";
import { TABS } from "../shared-constants";
import { VImage } from "./VImage";

//// Styling Component ----------------------------------------------------------------------

const getAnimation = keyframes`
    from  { transform: scale3d(1, 1, 1); },
    30%  { transform: scale3d(1.25, 0.75, 1);   },
    40%  { transform: scale3d(0.75, 1.25, 1);   },
    50%  { transform: scale3d(1.15, 0.85, 1);  },
    65%  { transform: scale3d(0.95, 1.05, 1);   },
    75%  { transform: scale3d(1.05, 0.95, 1);  },
    to  { transform: scale3d(1, 1, 1);   },
`;

const MainGridBackground = styled(Grid)(({ theme }) => ({
  direction: "column",
  justify: "flex-end",
  alignItems: "right",
  height: "1000px",
  backgroundImage: `url(${introBg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: `calc(100vw)`,
  padding: "190px 0 100px 0",
  [theme.breakpoints.down("sm")]: {
    padding: "70px 0px",
    height: "900px",
  },
}));

const CustomSkeletonBox = styled(Box)(({ theme }) => ({
  maxWidth: "1500px",
  margin: "0px auto 3rem auto",
  width: "90%",
  display: "flex",
  alignItems: "center",

  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const CustomLeftColumnItems = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  paddingLeft: "2rem",
  [theme.breakpoints.down("lg")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    paddingRight: "2rem",
    paddingBottom: "3rem",
    width: "90%",
  },
}));

const AnimatedLetterTypography = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  animationFillMode: "both",
  animationDuration: "1s",
  letterSpacing: "2px",
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
  "&:hover": {
    animation: `${getAnimation} 1s`,
    color: "#ffd700",
  },
}));

const RocketItemBox = styled(Box)(({ theme }) => ({
  width: "40%",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const WelcomeToPortfolioBox = styled(Box)(({ theme }) => ({
  fontWeight: "700",
  letterSpacing: "0.8px",
  padding: "8px 10px",
  background:
    "linear-gradient(90.21deg, rgba(74, 47, 189, 0.9) -5.91%, rgba(170, 54, 124, 0.5) 111.58%)",
  border: "1px solid rgba(255, 255, 255, 0.5)",
  fontSize: "20px",
  marginBottom: "16px",
  display: "inline-block",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.1rem",
  },
}));

const CustomLoopIntroItem = styled(Typography)(({ theme }) => ({
  height: "70px",
  fontSize: "3rem",
  marginTop: "0.1rem",
  [theme.breakpoints.down("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "2.1rem",
  },
  [theme.breakpoints.down("sm")]: {
    height: "40px",
    fontSize: "1.6rem",
  },
}));

const MyDescriptionTypography = styled(Typography)(({ theme }) => ({
  marginTop: "1rem",
  flexGrow: 1,
  textAlign: "justify",
}));

const LinkSendToConnect = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  marginTop: "1.8rem",
  textDecoration: "none",
}));

//// --------------------------------------------------------------------------------------------

const Introduction = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(150 - Math.random() * 50);

  const toRotate = useMemo(
    () => ["a Software Developer", "a Fun Collaborator"],
    []
  );
  const period = useMemo(() => 3000, []);
  const myName = useMemo(
    () => ["H", "i", "!", " ", "I", "'", "m", " ", "V", "i", "e", "t", ","],
    []
  );

  const tick = useCallback(() => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(150 - Math.random() * 50);
    }
  }, [isDeleting, loopNum, period, text.length, toRotate]);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [delta, tick]);

  return (
    <main>
      <MainGridBackground id={TABS["home"][1]} container>
        <CustomSkeletonBox>
          <CustomLeftColumnItems>
            <WelcomeToPortfolioBox>
              Welcome to my Portfolio
            </WelcomeToPortfolioBox>
            <Box>
              {myName.map((char, i) => (
                <AnimatedLetterTypography key={char + i}>
                  {char === " " ? "\u00A0" : char}
                </AnimatedLetterTypography>
              ))}
            </Box>

            <CustomLoopIntroItem>{text}</CustomLoopIntroItem>
            <MyDescriptionTypography>
              Welcome! I'm a versatile Full Stack Engineer, specializing in
              JavaScript, React, Node.js, and Java. With a knack for innovation
              and a commitment to user satisfaction, I've successfully developed
              applications for thousands of users.
            </MyDescriptionTypography>
            <LinkSendToConnect
              to={TABS["contact"][1]}
              href={`#${TABS["contact"][1]}`}
              smooth
            >
              <Button className="btn">
                <span>Let's Connect</span>
              </Button>
            </LinkSendToConnect>
          </CustomLeftColumnItems>
          <RocketItemBox>
            <VImage />
          </RocketItemBox>
        </CustomSkeletonBox>
      </MainGridBackground>
    </main>
  );
};

export default Introduction;
