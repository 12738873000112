import { Button, Drawer, List, ListItem, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-scroll";
import { TABS } from "../shared-constants";

//// Styling Component ----------------------------------------------------------------------
const styles = {
  mobileSideMenu: {
    height: "100%",
    backgroundColor: "rgba(0, 10, 31, 255)",
    color: "white",
  },
  hamburgerMenu: {
    color: "white",
    fontSize: "1.6rem",
  },
};
//// --------------------------------------------------------------------------------------------

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List style={styles.mobileSideMenu}>
          {Object.keys(TABS).map(function (keyName, keyIndex) {
            return (
              <ListItem key={keyName} onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to={TABS[keyName][1]} smooth>
                    {keyName === "contact" ? (
                      <Button className="btn">
                        <span>{TABS[keyName][0]}</span>
                      </Button>
                    ) : (
                      TABS[keyName][0]
                    )}
                  </Link>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <AiOutlineMenu
        style={styles.hamburgerMenu}
        onClick={() => setOpenDrawer(!openDrawer)}
      />
    </>
  );
}
export default DrawerComponent;
