import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import Introduction from "./components/banner/Introduction";
import Contact from "./components/contact-me/Contact";
import { Footer } from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Projects from "./components/projects/Projects";
import Skill from "./components/skill/Skill";

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(", "),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Introduction />
      <Skill />
      <Projects />
      <Contact />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
