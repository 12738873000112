import { Button, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React from "react";
import { Link } from "react-scroll";
import navIcon from "../../assets/logo.webp";
import { TABS } from "../shared-constants";
import DrawerComponent from "./DrawerComponent";

//// Styling Component ----------------------------------------------------------------------

const CustomMainNavBar = styled(Box)(({ theme }) => ({
  boxShadow: "none",
  background: "transparent",
}));

const CustomTabsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.8rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "2rem",
  },
}));

const CustomToolbar = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
}));

const CustomLogoBox = styled(Box)(({ theme, ismobile }) => ({
  maxHeight: "70px",
  maxWidth: ismobile,
  objectFit: "cover",
}));

const CustomLinkTabs = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  fontWeight: "700",
  textDecoration: "none",
  color: "inherit",
}));

//// --------------------------------------------------------------------------------------------

const NavMain = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <nav>
      <CustomMainNavBar position="static">
        <CustomToolbar>
          <CustomLogoBox
            component="img"
            ismobile={isMobile ? "150px" : "200px"}
            src={navIcon}
            alt="Navigate Icon"
          />
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <CustomTabsBox>
              <CustomLinkTabs
                to={TABS["home"][1]}
                href={`#${TABS["home"][1]}`}
                smooth
              >
                {TABS["home"][0]}
              </CustomLinkTabs>
              <CustomLinkTabs
                to={TABS["skills"][1]}
                offset={-171}
                href={`#${TABS["skills"][1]}`}
                smooth
              >
                {TABS["skills"][0]}
              </CustomLinkTabs>
              <CustomLinkTabs
                to={TABS["projects"][1]}
                href={`#${TABS["projects"][1]}`}
                smooth
              >
                {TABS["projects"][0]}
              </CustomLinkTabs>
              <CustomLinkTabs
                to={TABS["contact"][1]}
                href={`#${TABS["contact"][1]}`}
                smooth
              >
                <Button className="btn">
                  <span>{TABS["contact"][0]}</span>
                </Button>
              </CustomLinkTabs>
            </CustomTabsBox>
          )}
        </CustomToolbar>
      </CustomMainNavBar>
    </nav>
  );
};

export default NavMain;
