import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React, { useState } from "react";
import contactImg from "../../assets/contact-img.svg";
import { TABS } from "../shared-constants";

//// Styling Component ----------------------------------------------------------------------

const styles = {
  formStyle: {
    width: "100%",
  },
  customButtonStyle: {
    margin: "1.5rem 0 0 0",
    width: "150px",
    padding: "10px",
  },
};

// const scaleImage = keyframes`
//     0%  { transform: scale(0); },
//     100%  { transform: scale(1.0);   },
// `;

const BackgroundGradient = styled(Box)(({ theme }) => ({
  background: "linear-gradient(90.21deg, #281149 20.91%, #2F0923 100.58%)",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  padding: "130px 0px",
}));

const CustomContactSection = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  justifyContent: "space-evenly",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ContactImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "50%",
  justifyContent: "start",
  // animationDuration: "1s",
  // animation: `${scaleImage} 1s`,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    width: "100%",
  },
}));

const GetInTouchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

/// Follow this page: https://mui.com/material-ui/react-text-field/
const StyledTextInput = styled(TextField)(({ theme }) => ({
  width: "48%",
  margin: "10px 0",
  fontWeight: 500,
  background: "rgba(255, 255, 255, 0.2)",
  borderRadius: "20px",

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "20px",
    },
    color: "white",
    "&.Mui-focused": {
      border: `none`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `none`,
      },
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const MultiLineText = styled(TextField)(({ theme }) => ({
  width: "100%",
  margin: "10px 0",
  fontWeight: 500,
  background: "rgba(255, 255, 255, 0.2)",
  borderRadius: "20px",

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "20px",
    },
    color: "white",
    "&.Mui-focused": {
      border: `none`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: `none`,
      },
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const GetDisplayText = styled(Typography)(({ theme }) => ({
  fontSize: "45px",
  fontWeight: 700,
  padding: "20px 0",

  [theme.breakpoints.down("sm")]: {
    fontSize: "35px",
  },
}));

const FormControlBox = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "20px",
  },
}));

const ContactImage = styled(Box)(({ theme }) => ({
  width: "80%",
}));

const CustomSkeletonButton = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

//// --------------------------------------------------------------------------------------------

const Contact = () => {
  const initContactInfo = {
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    message: "",
  };
  const [contactInfo, setContactInfo] = useState(initContactInfo);
  const [submitButtonText, setSubmitButtonText] = useState("Send");

  const changeContactInfo = (e) => {
    setContactInfo((info) => ({
      ...info,
      [e.target.name]: e.target.value,
    }));
  };

  const submitContactInfo = async (e) => {
    if (
      contactInfo.firstName !== "" &&
      contactInfo.lastName !== "" &&
      contactInfo.email !== "" &&
      contactInfo.message !== ""
    ) {
      e.preventDefault();
      setSubmitButtonText("Sending");
      try {
        await fetch(`${process.env.REACT_APP_FORM}`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            "first-name": contactInfo.firstName,
            "last-name": contactInfo.lastName,
            "email-address": contactInfo.email,
            telephone: contactInfo.tel,
            message: contactInfo.message,
          }),
        });
        setContactInfo(initContactInfo);
        setSubmitButtonText("Send");
      } catch (error) {
        setSubmitButtonText("Send");
        throw new Error(`Something went wrong: ${error.message || error}`);
      }
    }
  };

  return (
    <main>
      <BackgroundGradient id={TABS["contact"][1]}>
        <CustomContactSection>
          <ContactImageBox>
            <ContactImage
              component="img"
              src={contactImg}
              alt="Contact Image"
            />
          </ContactImageBox>
          <FormControlBox>
            <GetDisplayText>Get In Touch</GetDisplayText>
            <form
              // action={process.env.REACT_APP_FORM}
              // method="POST"
              style={styles.formStyle}
            >
              <GetInTouchBox>
                <StyledTextInput
                  required
                  value={contactInfo.firstName}
                  name="firstName"
                  // id="first-name"
                  label="First Name"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => changeContactInfo(e)}
                />
                <StyledTextInput
                  required
                  value={contactInfo.lastName}
                  name="lastName"
                  // id="last-name"
                  label="Last Name"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => changeContactInfo(e)}
                />
              </GetInTouchBox>
              <GetInTouchBox>
                <StyledTextInput
                  required
                  value={contactInfo.email}
                  name="email"
                  // id="email-address"
                  label="Email"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => changeContactInfo(e)}
                />
                <StyledTextInput
                  value={contactInfo.tel}
                  name="tel"
                  // id="telephone"
                  label="Telephone"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => changeContactInfo(e)}
                />
              </GetInTouchBox>
              <GetInTouchBox>
                <MultiLineText
                  required
                  value={contactInfo.message}
                  name="message"
                  // id="message"
                  label="Message"
                  multiline
                  rows={4}
                  autoComplete="off"
                  onChange={(e) => changeContactInfo(e)}
                ></MultiLineText>
              </GetInTouchBox>
              <CustomSkeletonButton>
                <Button
                  disabled={submitButtonText === "Sending"}
                  type="submit"
                  className="btn"
                  style={styles.customButtonStyle}
                  onClick={(e) => submitContactInfo(e)}
                >
                  <span>{submitButtonText}</span>
                </Button>
              </CustomSkeletonButton>
            </form>
          </FormControlBox>
        </CustomContactSection>
      </BackgroundGradient>
    </main>
  );
};

export default Contact;
