import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSkillBackground from "../../assets/color-skills-bg.png";
import cssImg from "../../assets/css.webp";
import firebaseImg from "../../assets/firebase.webp";
import flutterImg from "../../assets/flutter.webp";
import githubImg from "../../assets/github.webp";
import htmlImg from "../../assets/html.webp";
import javascriptImg from "../../assets/javascript.webp";
import materialUI from "../../assets/materialUI.webp";
import mongoImg from "../../assets/mongo.webp";
import nodeImg from "../../assets/node.webp";
import postgresqlImg from "../../assets/postgresql.webp";
import pythonImg from "../../assets/python.webp";
import reactImg from "../../assets/react.webp";
import reduxImg from "../../assets/redux.webp";
import riverpodImg from "../../assets/riverpod.webp";
import auth0Img from "../../assets/auth0.webp";
import reactQueryImg from "../../assets/react-query.webp";
import stripeImg from "../../assets/stripe.webp";
import socketiomg from "../../assets/socket-io.webp";
import prismaImg from "../../assets/prisma.webp";
import beautifulDnDImg from "../../assets/react-beautiful-dnd.webp";
import githubActionImg from "../../assets/github-actions.webp";
import rubyOnRailsImg from "../../assets/ruby-on-rails.webp";
import cypressImg from "../../assets/cypress.webp";
import storybookImg from "../../assets/storybook.webp";
import tailwindImg from "../../assets/tailwind.webp";
import typescriptImg from "../../assets/typescript.webp";
import awsImg from "../../assets/aws.webp";
import dockerImg from "../../assets/docker.webp";
import mochaImg from "../../assets/mocha.webp";
import chaiImg from "../../assets/chai.webp";
import jestImg from "../../assets/jest.webp";
import rspecImg from "../../assets/rspec.webp";
import { TABS } from "../shared-constants";

const skills = {
  first: [
    [
      "JavaScript",
      javascriptImg,
      "https://www.ecma-international.org/publications-and-standards/standards/ecma-262/",
    ],
    ["React.js", reactImg, "https://reactjs.org/"],
  ],
  second: [
    ["Node.js", nodeImg, "https://nodejs.org/en/"],
    ["TypeScript", typescriptImg, "https://www.typescriptlang.org/"],
  ],
  third: [
    ["Auth0", auth0Img, "https://auth0.com/"],
    [
      "Beautiful DnD",
      beautifulDnDImg,
      "https://github.com/atlassian/react-beautiful-dnd#readme",
    ],
  ],
  fourth: [
    ["React Query", reactQueryImg, "https://tanstack.com/query/latest"],
    ["Redux + RTK", reduxImg, "https://redux-toolkit.js.org/"],
  ],
  fifth: [
    ["Stripe", stripeImg, "https://stripe.com/en-ca"],
    ["Socket.io", socketiomg, "https://socket.io/"],
  ],
  sixth: [
    ["Prisma", prismaImg, "https://www.prisma.io/"],
    ["PostgreSQL", postgresqlImg, "https://www.postgresql.org/"],
  ],
  seventh: [
    ["MongoDB", mongoImg, "https://www.mongodb.com/home"],
    ["Firebase", firebaseImg, "https://firebase.google.com/"],
  ],
  eighth: [
    ["HTML", htmlImg, "https://html.spec.whatwg.org/"],
    ["CSS", cssImg, "https://www.w3.org/TR/CSS/#css"],
  ],
  ninth: [
    ["Tailwind", tailwindImg, "https://tailwindcss.com/"],
    ["Material UI", materialUI, "https://mui.com"],
  ],
  fifteen: [
    ["Mocha", mochaImg, "https://mochajs.org/"],
    ["Chai", chaiImg, "https://www.chaijs.com/"],
  ],
  sixteen: [
    ["Jest", jestImg, "https://jestjs.io/"],
    ["RSpec", rspecImg, "https://rspec.info/"],
  ],
  thirteen: [
    ["Cypress", cypressImg, "https://www.cypress.io/"],
    ["Storybook", storybookImg, "https://storybook.js.org/"],
  ],
  tenth: [
    ["Flutter", flutterImg, "https://flutter.dev/multi-platform"],
    ["Riverpod", riverpodImg, "https://riverpod.dev"],
  ],
  eleventh: [
    ["GitHub Actions", githubActionImg, "https://github.com/features/actions"],
    ["GitHub", githubImg, "https://github.com/"],
  ],
  twelfth: [
    ["Ruby on Rails", rubyOnRailsImg, "https://rubyonrails.org/"],
    ["Python", pythonImg, "https://www.python.org/"],
  ],
  fourteen: [
    ["AWS", awsImg, "https://aws.amazon.com/"],
    ["Docker", dockerImg, "https://www.docker.com/"],
  ],
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

//// Styling Component ----------------------------------------------------------------------

const CustomSkeletonBox = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  display: "flex",
  justifyContent: "center",
}));

const CustomBackgroundBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${colorSkillBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "left",
  width: `calc(100vw)`,
  padding: "800px 0 500px 0",
  position: "absolute",
  transform: "scale(1, -1)",
  zIndex: "-4",
}));

const CustomSkillBackgroundBox = styled(Box)(({ theme }) => ({
  width: "70%",
  background: "#151515",
  borderRadius: "64px",
  textAlign: "center",
  padding: "40px 50px 70px 50px",
  marginTop: "-60px",
  boxShadow: "0 1rem 2rem hsl(0 0% 0% / 20%)",
}));

const CustomTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "45px",
  fontWeight: 700,
}));

// const CustomDescriptionTypography = styled(Typography)(({ theme }) => ({
//   color: "#B8B8B8",
//   fontSize: "18px",
//   letterSpacing: "0.8px",
//   lineHeight: "1.5em",
//   margin: "14px 0 75px 0",
// }));

const CustomItemCarousel = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  duration: "1s",
  padding: "30px",
  ":hover": {
    scale: "120%",
  },
}));

const LanguagesIconBox = styled(Box)(({ theme }) => ({
  maxHeight: "100px",
  height: "40%",
  margin: "0 auto 15px auto",
  [theme.breakpoints.down("lg")]: {
    maxHeight: "80px",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "60px",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "50px",
  },
}));

//// --------------------------------------------------------------------------------------------

const Skill = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };
  return (
    <main>
      <CustomSkeletonBox id={TABS["skills"][1]}>
        <CustomSkillBackgroundBox>
          <CustomTitleTypography>Skills</CustomTitleTypography>
          {/* <CustomDescriptionTypography>
            I am driven to produce high-quality work and am always eager to
            learn new technologies.
          </CustomDescriptionTypography> */}
          <Carousel responsive={responsive} autoPlay infinite>
            {Object.keys(skills).map(function (skillsPair, keyIndex) {
              return (
                <div>
                  <CustomItemCarousel
                    key={skills[skillsPair][0][0]}
                    onClick={() => openInNewTab(skills[skillsPair][0][2])}
                  >
                    <LanguagesIconBox
                      component="img"
                      src={skills[skillsPair][0][1]}
                      alt={`Skill name: ${skills[skillsPair][0][0]}`}
                    />
                    <Typography>{skills[skillsPair][0][0]}</Typography>
                  </CustomItemCarousel>
                  <CustomItemCarousel
                    key={skills[skillsPair][1][0]}
                    onClick={() => openInNewTab(skills[skillsPair][1][2])}
                  >
                    <LanguagesIconBox
                      component="img"
                      src={skills[skillsPair][1][1]}
                      alt={`Skill name: ${skills[skillsPair][1][0]}`}
                    />
                    <Typography>{skills[skillsPair][1][0]}</Typography>
                  </CustomItemCarousel>
                </div>
              );
            })}
          </Carousel>
        </CustomSkillBackgroundBox>
        <CustomBackgroundBox />
      </CustomSkeletonBox>
    </main>
  );
};

export default Skill;
