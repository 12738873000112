export const SOCIAL_MEDIAS = {
  linkedIn: [
    "Linkedin",
    "https://www.linkedin.com/in/viettran94/",
    "rgb(37 99 235)",
  ],
  gitHub: ["Github", "https://github.com/tienviet10/", "#333333"],
  email: [
    "Email",
    `mailto:${process.env.REACT_APP_EMAIL}?subject=From your portfolio`,
    "#6fc2b0",
  ],
  resume: [
    "Resume",
    "https://firebasestorage.googleapis.com/v0/b/dh-website-practice.appspot.com/o/Resume%2FViet-Tran.pdf?alt=media",
    "#565f69",
  ],
};

export const TABS = {
  home: ["Home", "home"],
  skills: ["Skills", "skills"],
  projects: ["Projects", "projects"],
  contact: ["Contact Me", "connect"],
};

export const PROJECT_LINKS = {
  reme: "https://remeapp.netlify.app/",
  frontend: "https://github.com/tienviet10/todos-client/",
  backend: "https://github.com/tienviet10/todos-server/",
  jobnomics: "https://jobnomics.net/",
  jobnomicsFE: "https://github.com/tienviet10/jobnomics",
  jobnomicsBE: "https://github.com/tienviet10/jobnomics-api",
  foodwise: "https://www.foodwise.live/",
  foodwiseGitHub: "https://github.com/tienviet10/Food-Pickup",
  tinyapp: "http://54.237.12.196/login",
  tinyappGitHub: "https://github.com/tienviet10/tinyapp",
  // jungle: "http://tinyapp.ca/login",
  jungleGitHub: "https://github.com/tienviet10/Jungle",
  gamelist: "https://gamelistapp.netlify.app/home",
  gamelistFeGitHub: "https://github.com/tienviet10/game-list-fe",
  gamelistBeGitHub: "https://github.com/tienviet10/gamelist-spring-be",
  dishHubWebsite: "https://www.dishhub.ca/",
  iosDishHub: "https://apps.apple.com/app/id1544640658",
  androidDishHub: "https://play.google.com/store/apps/details?id=dishhub.dishhub",
};
