import { List, ListItem } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { SOCIAL_MEDIAS } from "../shared-constants";

//// Styling Component ----------------------------------------------------------------------
const CustomSocialItems = styled(ListItem)(({ theme, iconcolor }) => ({
  width: "170px",
  height: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginLeft: "-110px",
  cursor: "pointer",
  backgroundColor: iconcolor,
  transition: "0.3s ease-in-out",
  "&: hover": {
    marginLeft: "-10px",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const CustomSocialList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  top: "35%",
  left: 0,
  zIndex: "2",
}));

const CustomTextItems = styled(List)(({ theme }) => ({
  margin: "0px 0px 0 15px",
  fontWeight: "700",
}));

//// --------------------------------------------------------------------------------------------

const Social = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <CustomSocialList>
      <CustomSocialItems
        iconcolor={SOCIAL_MEDIAS["linkedIn"][2]}
        onClick={() => openInNewTab(SOCIAL_MEDIAS["linkedIn"][1])}
      >
        <CustomTextItems>{SOCIAL_MEDIAS["linkedIn"][0]}</CustomTextItems>
        <FaLinkedin size={30} />
      </CustomSocialItems>
      <CustomSocialItems
        iconcolor={SOCIAL_MEDIAS["gitHub"][2]}
        onClick={() => openInNewTab(SOCIAL_MEDIAS["gitHub"][1])}
      >
        <CustomTextItems>{SOCIAL_MEDIAS["gitHub"][0]}</CustomTextItems>
        <FaGithub size={30} />
      </CustomSocialItems>
      <CustomSocialItems
        iconcolor={SOCIAL_MEDIAS["email"][2]}
        onClick={() => openInNewTab(SOCIAL_MEDIAS["email"][1])}
      >
        <CustomTextItems>{SOCIAL_MEDIAS["email"][0]}</CustomTextItems>
        <HiOutlineMail size={30} />
      </CustomSocialItems>
      <CustomSocialItems
        iconcolor={SOCIAL_MEDIAS["resume"][2]}
        onClick={() => openInNewTab(SOCIAL_MEDIAS["resume"][1])}
      >
        <CustomTextItems>{SOCIAL_MEDIAS["resume"][0]}</CustomTextItems>
        <BsFillPersonLinesFill size={30} />
      </CustomSocialItems>
    </CustomSocialList>
  );
};

export default Social;
