import React from "react";
import { animated, useSpring } from "react-spring";
import { useAnimatedPath } from "./useAnimatedPath";

const VPart = ({ fill, color, d, toggle }) => {
  const animationStrokeProps = useAnimatedPath({ toggle });
  const animationFillStyle = useSpring({
    fill: toggle ? fill : "transparent",
    delay: 800,
  });
  return (
    <animated.path
      {...animationStrokeProps}
      style={{
        ...animationStrokeProps.style,
        ...animationFillStyle,
      }}
      strokeWidth="3"
      stroke={color}
      d={d}
    />
  );
};

export default VPart;
