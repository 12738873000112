import { useEffect, useState } from "react";
import VPart from "./VPart";

export function VImage() {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setToggle(true);
    }, 500);
  }, []);

  return (
    <svg viewBox="0 0 610 634">
      <VPart
        toggle={toggle}
        d="M 34.28672 29.729553 L 222.757385 559.917969 L 277.711426 605.9729 L 387.712372 605.082764 L 583.237488 79.27771 L 535.288452 28.229797 L 415.291534 25.109985 L 299.935089 381.994995 L 200.238708 77.895264 L 149.286667 29.84436 L 34.28672 29.729553 Z"
        color="#fff"
        fill="#251147"
      />
      <VPart
        toggle={toggle}
        d="M 46.278725 37.741516 L 142.278671 37.837402 L 277.842255 474.972961 L 423.281525 35.117981 L 525.278503 38.219849 L 332.767334 550.027832 L 227.76738 549.922974 L 46.278725 37.741516 Z"
        color="#DCDADA"
        fill="#DCDADA"
      />
    </svg>
  );
}
